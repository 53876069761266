<template>
<div class="companyInfo-card">
  <div class="title">企业信息</div>
  <div>
    <p>企业名称：</p>
    <p>{{val.companyName}}</p>
  </div>
  <div>
    <p>企业类型：</p>
    <p>{{val.companyType | dict("COMPANY_TYPE")}}</p>
  </div>
  <div>
    <p>所属行业：</p>
    <p>{{val.industryType | dict("INDUSTRY")}}</p>
  </div>
  <div>
    <p>企业规模：</p>
    <p>{{val.companyScaleType | dict("COMPANY_SCALE_TYPE")}}</p>
  </div>
  <div>
    <p>法人代表：</p>
    <p>{{val.legalPersonName}}</p>
  </div>
  <div>
    <p>统一社会信用代码：</p>
    <p>{{val.socialCreditCode}}</p>
  </div>
  <div>
    <p>企业地址：</p>
    <p>{{val.companyAddress}}</p>
  </div>
  <div>
    <p>企业简介：</p>
    <p class="van-multi-ellipsis--l2">{{val.companyBrief}}</p>
  </div>
  <div>
    <p>联系人：</p>
    <p>{{val.contactPerson}}</p>
  </div>
  <div>
    <p>联系电话：</p>
    <p>{{val.contactPhone | capitalize}}</p>
  </div>
</div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: 'companyInfo_card',
  props:{
    val:{
      type:Object,
      default:function (){
        return ''
      }
    }
  },
  mounted () {
    this.LoadDictData(["INDUSTRY"]);
  },
  methods:{
    ...mapActions(["LoadDictData"]),
  }
}
</script>

<style scoped lang="scss">
.companyInfo-card{
  width: 343px;
  background: #FFFFFF;
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  padding: 0 16px;
  margin-top: 24px;
  margin-bottom: 16px;
  >.title{
    height: 51px;
    font-size: 14px;
    //font-weight: 500;
    line-height: 51px;
    color: #262626;
    border-bottom: #EEEEEE solid 1px;
    margin-bottom: 12px;
  }
  &>:not(:first-child){
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 12px;
    &>:first-child{
      font-size: 14px;
      color: #999999;
      //width: 114px;
      white-space: nowrap;
    }
    &>:last-child{
      font-size: 14px;
      color: #262626;
      //max-width: 182px;
      white-space:normal;
      word-break:break-all;
    }
  }
}
</style>
