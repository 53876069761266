<template>
  <div class="company-info">

    <div class="companyInfo-card">
      <div class="title">企业信息</div>
      <div>
        <p>企业名称：</p>
        <p>{{ val.companyName }}</p>
      </div>
      <div>
        <p>企业类型：</p>
        <p>{{ val.companyType | dict("COMPANY_TYPE") }}</p>
      </div>
      <div>
        <p>所属行业：</p>
        <p>{{ val.industryType | dict("INDUSTRY") }}</p>
      </div>
      <div>
        <p>企业规模：</p>
        <p>{{ val.companyScaleType | dict("COMPANY_SCALE_TYPE") }}</p>
      </div>
      <div>
        <p>法人代表：</p>
        <p>{{ val.legalPersonName }}</p>
      </div>
      <div>
        <p>统一社会信用代码：</p>
        <p>{{ val.socialCreditCode }}</p>
      </div>
      <div>
        <p>企业地址：</p>
        <p>{{ val.companyAddress }}</p>
      </div>
      <div>
        <p>联系人：</p>
        <p>{{ val.contactPerson }}</p>
      </div>
      <div>
        <p>联系电话：</p>
        <p>{{ val.contactPhone | capitalize }}</p>
      </div>
    </div>

    <div class="companyBrief">
      <p>企业简介</p>
      <div class="item"></div>
      <div class="company-info-desc" :style="{height:isExpand?'auto':'176px'}">
        <p style="line-height: 22px;">
          {{ val.companyBrief
          }}<span
          style="line-height: 22px;"
          v-if="isExpandShow"
          :class="{ 'info-expand': isExpand }"
          @click="isExpand = !isExpand"
        >
              <template v-if="!isExpand"><span>...</span>展开<van-icon name="arrow-down"/></template>
              <template v-if="isExpand">收起<van-icon name="arrow-up" /></template>
            </span>
        </p>
      </div>
    </div>

  </div>
</template>

<script>

import companyInfo_card from '@/page/verified/components/companyInfo_card'
import { getH5CurrentCompany} from "@/api/res-company";
import { Toast } from 'vant'
import { mapActions, mapGetters } from 'vuex'
let boxHeight = 0;
let descHeight = 0;
export default {
  name: "company_info",
  components: {
    "companyInfo-card": companyInfo_card,
  },
  data() {
    return {
      val: "",
      isExpandShow: false,
      isExpand: false,
    };
  },
  computed:mapGetters(["dict"]),

  mounted() {
    boxHeight = document.querySelector(".company-info-desc").offsetHeight;
    this.LoadDictData(["INDUSTRY"]);
  },

  created() {
    document.title = "企业信息";
    this.loadCompany();
  },



  methods: {

    ...mapActions(["LoadDictData"]),

      async loadCompany() {
      this.$toast.loading({
        message: "加载中",
        forbidClick: true,
      });
        await  getH5CurrentCompany().then((company) => {
        this.$toast.clear();
        this.val = company;
        setTimeout(() => {
          descHeight = document.querySelector(".company-info-desc>p").offsetHeight;
          if (boxHeight < descHeight) {
            this.isExpandShow = true;
          } else {
            this.isExpandShow = false;
          }
        }, 500);
      });
    },
  },

};
</script>

<style scoped lang="scss">
.company-info {
  width: 100vw;
  min-height: 100vh;
  background: #f6f6f6;
  padding: 24px 16px;
}

.companyBrief {
  padding: 0 16px 16px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  opacity: 1;
  border-radius: 4px;
  margin-top: 12px;
  > :first-child {
    font-size: 14px;
    //font-weight: 500;
    color: #262626;
    line-height: 51px;
  }
  > .item {
    height: 1px;
    background: #eeeeee;
    opacity: 1;
  }
  > :last-child {
    margin-top: 12px;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
  }
  .company-info-desc {
    height: 176px;
    overflow: hidden;
    position: relative;
    > p {
      > span {
        position: absolute;
        bottom: 0;
        right: 0;
        background: #fff;
        padding-left: 4px;
        color: #ea0b06;
        &.info-expand{
          position: static;
        }
        > span {
          color: #262626;
        }
      }
    }
  }
}

.companyInfo-card {
  width: 343px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  padding: 0 16px;

  margin-bottom: 16px;
  > .title {
    height: 51px;
    font-size: 14px;
    line-height: 51px;
    color: #262626;
    border-bottom: #eeeeee solid 1px;
    margin-bottom: 12px;
  }
  & > :not(:first-child) {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 12px;
    & > :first-child {
      font-size: 14px;
      color: #999999;
      //width: 114px;
      white-space: nowrap;
    }
    & > :last-child {
      font-size: 14px;
      color: #262626;
      //max-width: 182px;
      white-space: normal;
      word-break: break-all;
    }
  }
}
</style>
